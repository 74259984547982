import { FormControl } from '@angular/forms';
import { IconNamesEnum, IconName } from 'ngx-bootstrap-icons';

export function formatMapData(data: { coordinates: any[] }, coordinates: any) {
  const grouped = data.coordinates.reduce((acc, obj) => {
    const key = obj.name;
    acc[key] = acc[key] || {
      type: 'Feature',
      properties: { name: key, id: 0 },
      geometry: { type: 'Polygon', coordinates: [[]] },
    };
    acc[key].geometry.coordinates[0].push([obj.lng, obj.lat]);
    return acc;
  }, {});

  coordinates.features.push(...Object.values(grouped));

  return coordinates;
}

export type ConfigKey =
  | 'countries'
  | 'region_commercials'
  | 'zones'
  | 'zone_supervisors'
  | 'sectors'
  | 'point_of_sales';

type ColorConfigs = {
  [key in ConfigKey]: ColorConfig;
};

export type ColorConfig = {
  color: string;
  fillColor: string;
  hoverColor: string;
  hoverFillColor: string;
  opacity: number;
  weight: number;
  fillOpacity: number;
  zIndex: number;
  hoverOpacity: number;
  hoverFillOpacity: number;
};

export const mapConfigColors: ColorConfigs = {
  countries: {
    color: '#1f77b4',
    fillColor: '#1f77b4',
    hoverColor: '#DFA612',
    hoverFillColor: '#FAE042',
    opacity: 0.2,
    weight: 1,
    fillOpacity: 0.2,
    zIndex: 200,
    hoverOpacity: 0.7,
    hoverFillOpacity: 0.7,
  },
  region_commercials: {
    color: '#ff7f0e',
    fillColor: '#ff7f0e',
    hoverColor: '#DFA612',
    hoverFillColor: '#FAE042',
    opacity: 0.3,
    weight: 1.4,
    fillOpacity: 0.3,
    zIndex: 400,
    hoverOpacity: 0.7,
    hoverFillOpacity: 0.7,
  },
  zone_supervisors: {
    color: '#2ca02c',
    fillColor: '#2ca02c',
    hoverColor: '#DFA612',
    hoverFillColor: '#FAE042',
    opacity: 0.4,
    weight: 1.8,
    fillOpacity: 0.4,
    zIndex: 600,
    hoverOpacity: 0.7,
    hoverFillOpacity: 0.7,
  },
  sectors: {
    color: '#d62728',
    fillColor: '#d62728',
    hoverColor: '#DFA612',
    hoverFillColor: '#FAE042',
    opacity: 0.5,
    weight: 2.2,
    fillOpacity: 0.5,
    zIndex: 800,
    hoverOpacity: 0.7,
    hoverFillOpacity: 0.7,
  },
  zones: {
    color: '#0055b8',
    fillColor: '#0055b8',
    hoverColor: '#0055b8',
    hoverFillColor: '#0055b8',
    opacity: 0.5,
    fillOpacity: 0.5,
    weight: 3,
    zIndex: 1000,
    hoverOpacity: 0.7,
    hoverFillOpacity: 0.7,
  },
  point_of_sales: {
    color: '#016107',
    fillColor: '#016107',
    hoverColor: '#016107',
    hoverFillColor: '#016107',
    opacity: 0.5,
    fillOpacity: 0.5,
    weight: 3,
    zIndex: 1200,
    hoverOpacity: 0.7,
    hoverFillOpacity: 0.7,
  },
};

export interface IMapFilter {
  sector_ids: number[];
  zone_ids: number[];
  country_ids: number[];
  zone_supervisor_ids: number[];
  region_commercial_ids: number[];
}

export interface IMapFilters {
  sector_ids: number[];
  zone_ids: number[];
  country_ids: number[];
  zone_supervisor_ids: number[];
  region_commercial_ids: number[];
  selectedResources: ConfigKey[];
  resources: IMapResource[];
}

export interface IMapResource {
  label: string;
  value: ConfigKey;
  formControl: FormControl;
  data: any[];
  color: string;
  icon: IconNamesEnum | IconName;
}

export const mapResources: { label: string; value: string }[] = [
  { label: 'Pays', value: 'countries' },
  { label: 'Regions commerciale', value: 'regionCommercials' },
  { label: 'Zone superviseurs', value: 'zoneSupervisors' },
  { label: 'Secteur', value: 'sectors' },
  { label: 'Zone', value: 'zones' },
];

export const resourcesToShowInMap: IMapResource[] = [
  {
    label: 'Pays',
    value: 'countries',
    formControl: new FormControl(),
    data: [],
    color: '#1f77b4',
    icon: 'flag',
  },
  {
    label: 'Regions commerciale',
    value: 'region_commercials',
    formControl: new FormControl(),
    data: [],
    color: '#ff7f0e',
    icon: 'flag',
  },
  {
    label: 'Zone superviseurs',
    value: 'zone_supervisors',
    formControl: new FormControl(),
    data: [],
    color: '#2ca02c',
    icon: 'map',
  },
  {
    label: 'Secteur',
    value: 'sectors',
    formControl: new FormControl(),
    data: [],
    color: '#d62728',
    icon: 'columns-gap',
  },
  {
    label: 'Zone',
    value: 'zones',
    formControl: new FormControl(),
    data: [],
    color: '#0055b8',
    icon: 'pin-map',
  }, 
  {
    label: 'Point de vente',
    value: 'point_of_sales',
    formControl: new FormControl(),
    data: [],
    color: '#016107',
    icon: 'pin-map',
  },
];

export function getResourceToShowInMap(
  allResourcesToShowInMap: IMapResource[],
  resources: ConfigKey[]
) {
  return allResourcesToShowInMap.filter((rs) => resources.includes(rs.value));
}


export const getPrioritizedFilter = (filterIDs: IMapFilter): IMapFilter => {
  // Si zone_ids existe et n'est pas vide, il sera utilisé en priorité
  if (filterIDs.zone_ids && filterIDs.zone_ids.length > 0) {
    return { ...filterIDs, sector_ids: [], zone_supervisor_ids: [], region_commercial_ids: [], country_ids: [] };
  }

  // Si sector_ids existe et n'est pas vide
  if (filterIDs.sector_ids && filterIDs.sector_ids.length > 0) {
    return { ...filterIDs, zone_ids: [], zone_supervisor_ids: [], region_commercial_ids: [], country_ids: [] };
  }

  // Si zone_supervisor_ids existe et n'est pas vide
  if (filterIDs.zone_supervisor_ids && filterIDs.zone_supervisor_ids.length > 0) {
    return { ...filterIDs, zone_ids: [], sector_ids: [], region_commercial_ids: [], country_ids: [] };
  }

  // Si region_commercial_ids existe et n'est pas vide
  if (filterIDs.region_commercial_ids && filterIDs.region_commercial_ids.length > 0) {
    return { ...filterIDs, zone_ids: [], sector_ids: [], zone_supervisor_ids: [], country_ids: [] };
  }

  // Si country_ids existe et n'est pas vide (c'est le filtre par défaut si les autres sont vides)
  if (filterIDs.country_ids && filterIDs.country_ids.length > 0) {
    return { ...filterIDs, zone_ids: [], sector_ids: [], zone_supervisor_ids: [], region_commercial_ids: [] };
  }

  // Si aucun filtre n'est fourni, retourner les filtres tels quels (aucun filtrage ne sera appliqué)
  return filterIDs;
}